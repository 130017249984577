import Vue from 'vue';

import SitesInsert  from './Insert.vue';
import SitesUpdate  from './Update.vue';
import SitesDelete  from './Delete.vue';
import SiteExpand   from './SiteExpand.vue';
import SiteNotes    from './SiteNotes.vue';

Vue.component('v-modal-sites-insert',   SitesInsert);
Vue.component('v-modal-sites-update',   SitesUpdate);
Vue.component('v-modal-sites-delete',   SitesDelete);
Vue.component('v-site-expand',          SiteExpand);
Vue.component('v-drawer-site-notes',    SiteNotes);
