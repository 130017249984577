import Vue from 'vue';

import Gravatar                     from 'vue-gravatar';
import CompanyLogo                  from './CompanyLogo.vue';
import CreditsAlert                 from './CreditsAlert.vue';
import Header                       from './Header.vue';
import Fieldset                     from './Fieldset.vue';
import FieldsetBasic                from './FieldsetBasic.vue';
import Footer                       from './Footer.vue';
import Loader                       from './Loader.vue';
import Logo                         from './Logo.vue';
import NoResults                    from './NoResults.vue';
import PageTitle                    from './PageTitle.vue';
import SystemMessage                from './SystemMessage.vue';

Vue.component('v-gravatar',         Gravatar);
Vue.component('v-company-logo',     CompanyLogo);
Vue.component('v-credits-alert',    CreditsAlert);
Vue.component('v-header',           Header);
Vue.component('v-fieldset',         Fieldset);
Vue.component('v-fieldset-basic',   FieldsetBasic);
Vue.component('v-foot',             Footer);
Vue.component('v-loader',           Loader);
Vue.component('v-logo',             Logo);
Vue.component('v-no-results',       NoResults);
Vue.component('v-page-title',       PageTitle);
Vue.component('v-system-message',   SystemMessage);

import './readonly';
import './windows';
import './tests';
import './modals';

//PAGE SPECIFIC
import './sites';