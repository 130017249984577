<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 134">
    <path fill="#dce7f2" d="M160,96v38h-6.5v-6.5h-1.5v6.5h-9v-15h-3.25v15h-10.75v-6.5h-1.50002v6.5h-9v-15h-3.25v15h-10.75v-6.5h-1.5v6.5h-9v-15h-3.5v15h-10.75v-6.5h-1.5v6.5h-9v-15h-3.25v15h-10.75v-6.5h-1.5v6.5h-9v-15h-3.25v8.5h-.25v6.5h-10.5v-6.5h-1.5v6.5h-9.00001v-15h-3.25v15H6.25v-6.5h-1.5v6.5H0v-38H160Z"/>
    <path fill="#4499d4" d="M86.25,96h-10.5l-2.5-11c-8.75,10-16.75,13.25-28.25,13.25-28,0-44.75-22.24999-44.75-49.24999S17,0,45,0c20.5,0,37.75,11,40.25,32h-16c-1.75-12.5-12.25-18.75-24.25-18.75-20,0-28.5,17.75-28.5,35.75s8.5,35.75,28.5,35.75c16.75,.25,26-9.75,26.25-25.75h-25v-12.5h40v49.5Zm-2-93.75h75.75v14.25h-29.75V96h-16.5V16.5h-29.5V2.25Z"/>
  </svg>
</template>

<script>
  export default {
    name: 'GTMetrix.vue',
    props: {
      icon: {
        type: String,
        default: '$cancel'
      }
    }
  }
</script>