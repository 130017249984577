import Vue from 'vue';

import TestAccessibility              from './Accessibility.vue';
import TestClickjacking               from './Clickjacking.vue';
import TestDownload                   from './Download.vue';
import TestPerformance                from './Performance.vue';
import TestSpelling                   from './Spelling.vue';

Vue.component('v-test-accessibility', TestAccessibility);
Vue.component('v-test-clickjacking',  TestClickjacking);
Vue.component('v-test-download',      TestDownload);
Vue.component('v-test-performance',   TestPerformance);
Vue.component('v-test-spelling',      TestSpelling);
