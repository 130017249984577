<template>
  <div id="integrations"> 
    <v-modal-slack v-model="dialogSlack"></v-modal-slack>
    <v-modal-gtmetrix v-model="dialogGTM"></v-modal-gtmetrix>
    <v-modal-uptime-robot v-model="dialogUR"></v-modal-uptime-robot>
    <v-card elevation="0">
      <v-card-title>Integrations</v-card-title>
      <v-card-text>
        <v-row align="center">
          <v-col cols="1">
            <v-switch
              v-model="form.fields.managewp"
              inset
              light
              hide-details
              :true-value="1"
              :false-value="0"
              @change="toggle()"
            ></v-switch>
          </v-col>
          <v-col>
            <div class="py-2">
              <img src="https://res.cloudinary.com/hbpqzf9rf/image/upload/v1700189812/thirdparty-logos/ryyrophna6mecxuh8st1.png" class="integration-logos" crossorigin="anonymous" /><br>
              For more information about ManageWP, visit the <a href="https://www.managewp.com" target="_blank">website</a>.
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center">
          <v-col cols="1">
            <v-switch
              v-model="form.fields.slack"
              inset
              light
              :true-value="1"
              :false-value="0"
              hide-details
              @change="toggle()"
            ></v-switch>
          </v-col>
          <v-col>
            <div class="py-2">
              <img src="https://res.cloudinary.com/hbpqzf9rf/image/upload/v1700189836/thirdparty-logos/urag93zk7azw7zms7h8u.svg" class="integration-logos" crossorigin="anonymous" /><br>
              For more information about how to get this setup, please visit the official Slack <a href="https://api.slack.com/messaging/webhooks" target="_blank">documentation</a>.
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              color="blue-grey darken-4"
              dark
              inline
              small
              class="mt-2"
              @click="dialogSlack = true"
              rounded
              :disabled="form.fields.slack == 0"
            >
              <v-icon small>mdi-cog</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center">
          <v-col cols="1">
            <v-switch
              v-model="form.fields.gtmetrix"
              inset
              light
              hide-details
              :true-value="1"
              :false-value="0"
              @change="toggle()"
            ></v-switch>
          </v-col>
          <v-col>
            <div class="py-4">
              <img src="https://res.cloudinary.com/hbpqzf9rf/image/upload/v1700189867/thirdparty-logos/srfwtkfioc6zts0dqmn7.svg" class="integration-logos" crossorigin="anonymous" /><br>
              For more information about GTMetrix, visit the <a href="https://www.gtmetrix.com" target="_blank">website</a>.
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              color="blue-grey darken-4"
              dark
              inline
              small
              class="mt-2"
              @click="dialogGTM = true"
              rounded
              :disabled="form.fields.gtmetrix == 0"
            >
              <v-icon small>mdi-cog</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center">
          <v-col cols="1">
            <v-switch
              v-model="form.fields.uptimerobot"
              inset
              light
              hide-details
              :true-value="1"
              :false-value="0"
              @change="toggle()"
            ></v-switch>
          </v-col>
          <v-col>
            <div class="py-5">
              <img src="https://res.cloudinary.com/hbpqzf9rf/image/upload/v1700190304/thirdparty-logos/djo1ckzoamoz8ad6tfen.svg" class="integration-logos" crossorigin="anonymous" /><br>
              For more information about Uptime Robot, visit the <a href="https://www.uptimerobot.com" target="_blank">website</a>.
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              color="blue-grey darken-4"
              dark
              inline
              small
              class="mt-2"
              @click="dialogUR = true"
              rounded
              :disabled="form.fields.uptimerobot == 0"
            >
              <v-icon small>mdi-cog</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!--
        <v-divider></v-divider>
        <v-row align="center">
          <v-col cols="2">
            <v-switch
              disabled
              v-model="form.fields.mainwp"
              inset
              light
              hide-details
              :true-value="1"
              :false-value="0"
              @change="toggle()"
            ></v-switch>
          </v-col>
          <v-col>
            <div class="py-2">
              <h3>MainWP Integration</h3>
              For more information about MainWP, visit the <a href="https://www.mainwp.com" target="_blank">website</a>.
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center">
          <v-col cols="2">
            <v-switch
              disabled
              v-model="form.fields.infinitewp"
              inset
              light
              hide-details
              :true-value="1"
              :false-value="0"
              @change="toggle()"
            ></v-switch>
          </v-col>
          <v-col>
            <div class="py-4">
              <h3>InfiniteWP Integration</h3>
              For more information about InfiniteWP, visit the <a href="https://www.infinitewp.com" target="_blank">website</a>.
            </div>
          </v-col>
        </v-row>
        -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Integrations.vue',
  data() {
    return {
      dialogSlack: false,
      dialogGTM: false,
      dialogUR: false,
      form:{
        fields: {
          slack: 0,
          managewp: 0,
          mainwp: 0,
          infinitewp: 0,
          gtmetrix: 0,
          uptimerobot: 0,
        },
      },
    }
  },
  created(){
    this.get();
  },
  computed:{
    integrations(){
      return this.$store.getters['integrations/get'];
    },
  },
  methods:{
    async get(){
      await this.$store.dispatch("integrations/get");
      this.form.fields = { ...this.integrations };
    },
    async toggle(){
      await this.$store.dispatch("integrations/upsert", this.form.fields);
    },
  }
}
</script>

<style lang="scss">
  #integrations{
    .integration-logos{
      max-width: 150px;
      max-height: 30px;
    }
  }
</style>