import { render, staticRenderFns } from "./WordpressSecurityNews.vue?vue&type=template&id=115c8596&"
import script from "./WordpressSecurityNews.vue?vue&type=script&lang=js&"
export * from "./WordpressSecurityNews.vue?vue&type=script&lang=js&"
import style0 from "./WordpressSecurityNews.vue?vue&type=style&index=0&id=115c8596&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports