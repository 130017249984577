import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/wordpress';
const endpoints = {
  get:              model+'/get',
  exists:           model+'/exists',
  upsert:           model+'/upsert',
  delete:           model+'/delete',
}
const mutation_calls = {
  get:              'SET_GET',
  exists:           'SET_EXISTS',
  upsert:           'SET_UPSERT',
  delete:           'SET_DELETE',
  modal:            'SET_MODAL',
}

const state = () => ({
  showModal: false,
})

const getters = {
  showModal: state => state.showModal,
}

const actions = {
  async get(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.get, {
        'client_id': fields.client_id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.get, fields);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async exists(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.exists, {
        'client_id': fields.client_id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.exists, fields);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async update(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.update, {
        'username': fields.username,
        'password': fields.password,
        'wpadmin': fields.wpadmin,
        'client_id': fields.client_id
      })
      .then(response => {
        let payload = response;
        context.commit("sites/SET_WPEXISTS", {
          'client_id': fields.client_id,
          'wp_exists': 1
        }, { root: true });
        context.dispatch('system/setMessage', i18n.t("wordpress.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("wordpress.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async upsert(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.upsert, {
        'username': fields.username,
        'password': fields.password,
        'wpadmin': fields.wpadmin,
        'client_id': fields.client_id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.upsert, fields);
        context.dispatch('system/setMessage', i18n.t("wordpress.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("wordpress.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.delete, {
        'client_id': fields.client_id,
        'wp_exists': 0
      })
      .then(response => {
        let payload = response;
        context.commit("sites/SET_WPEXISTS", fields, { root: true });
        context.dispatch('system/setMessage', i18n.t("wordpress.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("wordpress.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
  toggleModal(context, toggle) {
    context.commit(mutation_calls.modal, toggle);
  }
}

const mutations = {
  SET_GET() {
  },
  SET_UPDATE() {
  },
  SET_DELETE() {
  },
  SET_EXISTS() {
  },
  SET_UPSERT(){
  },
  SET_MODAL(state, payload) {
    state.showModal = payload;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}