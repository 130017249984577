<template>
  <v-form
    ref="form"
    v-model="form.valid"
    lazy-validation>
    <v-card elevation="0">
      <v-card-title>Update User Info</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="form.fields.name"
              :rules="[$utilities.rules.required]"
              label="Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              type="email"
              v-model="form.fields.email"
              :rules="[$utilities.rules.required, $utilities.rules.isEmail]"
              label="Email"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="text-center">
            <v-btn x-large dark color="blue-grey darken-4" @click="save">
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>  
  </v-form>
</template>

<script>
export default {
  name: 'UserInfo.vue',
  data() {
    return {
      form:{
        valid: false,
        fields: {
          name:'',
          email: '',
        },
      },
    }
  },
  computed:{
    user(){
      return this.$store.getters['users/user'];
    },
  },
  created(){
    if(this.user){
      this.form.fields = {...this.user};
    }
  },
  methods:{
    save(){
      if(this.$refs.form.validate()){
        this.$store.dispatch("users/accountUpdate", this.form.fields)
      }
    },
  }
}
</script>

<style>

</style>