<template v-model="display">
  <div class="box-taskbar">
    <v-slide-y-transition>
      <v-card v-if="tasks.length" class="task-bar" outlined>
        <v-card-title class="task-bar-header"> Scan In Process </v-card-title>
        <v-card-text class="task-bar-content">
          <v-list dense>
            <v-list-item v-for="(task, index) in tasks" :key="index">
              <v-list-item-content>
                <v-list-item-title class="task-title">
                  <span>{{ `#${task.id}` }}</span>
                  <span>
                    {{ task.name }}
                  </span>
                  <v-btn icon @click="endTask(task.id)">
                    <v-icon color="red">mdi-close</v-icon>
                  </v-btn>
                </v-list-item-title>
                <v-progress-linear v-if="task.progress == 0" indeterminate :value="task.progress" color="green" class="mt-2 progress" height="10">
                  <template v-slot:default="{ value }">
                    <strong class="percentage">{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
                <v-progress-linear
                  v-else
                  :value="task.progress"
                  :buffer-value="task.progress < 90 ? task.progress + 10 : task.progress"
                  color="green"
                  class="mt-2 progress"
                  height="10"
                  striped
                  stream>
                  <template v-slot:default="{ value }">
                    <strong class="percentage">{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {
  name: "TaskProgress",
  data() {
    return {
      tasks: [],
    };
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    reset() {
      this.tasks = [];
    },
    items() {
      return this.tasks;
    },
    itemAdd(items) {
      items.forEach((item) => {
        this.tasks.push(item);
      });
    },
    itemUpdate(id, progress) {
      const task = this.tasks.find((task) => task.id === id);
      if (task) {
        task.progress = progress;
      }
    },
    itemRemove(id) {
      this.tasks = this.tasks.filter((task) => task.id !== id);
    },
    endTask(id) {
      const i = this.tasks.findIndex((task) => task.id === id);
      const item = this.tasks[i];
      this.$emit("end", item);
    },
  },
};
</script>

<style>
.box-taskbar {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 3000;
  width: 350px;
  min-height: 30px;
}
.task-title {
  display: flex;
  justify-content: space-between;
}
.percentage {
  font-size: 9px;
  color: #000;
}
.progress {
  background-color: #c2e5ca;
}
</style>
