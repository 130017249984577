<template>
  <v-card elevation="0">
    <v-card-title>
      Login Attempts (Last 10)
    </v-card-title>
    <v-card-text>
      <v-loader v-if="!logs"></v-loader>
      <v-alert light color="grey lighten-4" class="text-center" v-else-if="logs.length == 0">No data found</v-alert>
      <v-data-table
        v-else
        :headers="headers" 
        :items="logs" 
        item-key="name"
        :items-per-page=5
        sort-by="created_at"
        :sort-desc="true"
      >
        <template v-slot:item.created_at="{ item }">
          {{$date.fullDateTime2(item.created_at)}}
        </template>
        <template v-slot:item.pf="{ item }">
          <v-icon small color="blue-grey darken-4" v-if="item.pf == 'pass'">mdi-thumb-up</v-icon>
          <v-icon small color="red" v-else>mdi-thumb-down</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'UsersLogs.vue',
    data() {
      return {
        headers: [
          { text: "Pass/Fail", value: "pf", width: '20%', align: 'center', divider: true },
          { text: "Date", value: "created_at", width: '50%', align: 'center', divider: true },
          { text: "IP Address", value: "ip", width: '30%', align: 'center' },
        ],
      }
    },
    computed: {
      logs(){
        return this.$store.getters['logs/all'];
      }
    },
    created(){
      this.$store.dispatch("logs/all");
    },
  }
</script>

<style lang="scss" scoped>

</style>