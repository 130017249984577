<template>
  <div>
    <div class="text-center mb-4">
      <div v-if="Object.keys($slots).length === 0">Loading Data...</div>
      <div v-else>
        <slot></slot>
      </div>
    </div>
    <v-progress-linear
      color="green darken-2"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
  </div>
</template>

<script>
  export default {
    name: 'Loader.vue'
  }
</script>

<style lang="scss" scoped>

</style>