<template>
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.20168 120">
    <polygon fill="#c01818" points="21.5126 82.0168 21.5126 33.27731 52.10084 47.73109 52.10084 24.20168 0 0 0 95.79832 52.10084 120 52.10084 96.47059 21.5126 82.0168"/>
    <polygon fill="#75160d" points="83.02521 82.0168 83.02521 33.27731 52.10084 47.73109 52.10084 24.20168 104.20168 0 104.20168 95.79832 52.10084 120 52.10084 96.47059 83.02521 82.0168"/>
  </svg>
</template>

<script>
  export default {
    name: 'McAfee.vue',
    props: {
      icon: {
        type: String,
        default: '$cancel'
      }
    }
  }
</script>