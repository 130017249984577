<template>
  <v-dialog v-model="value" max-width="650px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Resend email</span>
        <v-btn small dark fab absolute right text @click="updateValue(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center py-5" v-if="users">
        <b>Are you sure you want resend an invite email to {{getUserName}}?</b>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn x-large dark color="blue-grey darken-4" @click="save()">Yes</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UsersResendInvite.vue',
  data() {
    return {
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: Number
    },
  },
  computed:{
    users(){
      return this.$store.getters['users/all'];
    },
    getUserName(){
      let user = this.users.find(user => user.id == this.id);
      return (user) ? user.name : "";
    }
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async save(){
      let self = this;
      await this.$store.dispatch('users/invite', {
        id: this.id
      }).then(() => {
        self.updateValue(false);
        self.systemMessage('User emailed!');
      });
    }
  }
}
</script>

<style>

</style>