<template>
  <div>
    <v-modal-wordpress v-model="dialogWordpress" :id="obj.id"></v-modal-wordpress>
    <v-menu offset-y v-for="(menu, groupi) in menus" :key="`menu-`+groupi" align="center">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          x-small
          plain
          text
          outlined
          class="mr-1"
        >
          {{menu.name}}
          <v-icon right small>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list class="d-flex flex-column align-start py-0 rounded-t-0" width="300px" dense color="primary" dark>
        <template v-for="(item, indexi) in menu.list">
          <v-list-item-group :key="indexi" style="width: 100%;" v-if="item.active" dense>
            <v-list-item @click="clickFunction(groupi, indexi)" class="pr-0 pl-3">
              <v-list-item-content class="py-0">
                <v-list-item-title class="text-caption pa-0">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon small class="mr-3">{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider v-if="indexi < menu.list.length-1" :key="`divider-`+indexi"></v-divider>
          </v-list-item-group>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'SiteExpand.vue',
  data() {
    return {
      dialogWordpress: false,
      menus:[
        {
          name: 'Wordpress',
          list: [
            {
              'title': 'WP Account Info',
              'icon': 'mdi-account-box',
              active: true,
            },
            {
              'title': 'Get Application Password',
              'icon': 'mdi-asterisk',
              active: true,
            },
            {
              'title': 'WP Admin',
              'icon': 'mdi-wordpress',
              active: true,
            },
            {
              'title': 'WP Site Health',
              'icon': 'mdi-medical-bag',
              active: true,
            },
            {
              'title': 'Wordfence',
              'icon': '$wordfence',
              active: true,
            },
            {
              'title': 'ManageWP',
              'icon': '$managewp',
              active: !this.$utilities.shouldDisable([!this.mwpExists(this.obj),!this.obj.active, !this.obj.managewp]),
            },
          ]
        },
        {
          name: 'Virus',
          list: [
            {
              'title': 'Google Safe Browsing',
              'icon': '$google',
              active: true,
            },
            {
              'title': 'McAfee Site Advisor',
              'icon': '$mcafee',
              active: true,
            },
            {
              'title': 'Norton Safe Web',
              'icon': '$norton',
              active: true,
            },
            {
              'title': 'Securi',
              'icon': '$sucuri',
              active: true,
            },
            {
              'title': 'VirusTotal Scan',
              'icon': '$virustotal',
              active: true,
            },
          ]
        },
        {
          name: 'Performance',
          list: [
            {
              'title': 'GTMetrix',
              'icon': '$gtmetrix',
              active: true, //this.integrations.gtmetrix,
              loading: this.obj.gtm_loading
            },
            {
              'title': 'Lighthouse',
              'icon': '$lighthouse',
              active: true,
            },
            {
              'title': 'Accessibility',
              'icon': '$wave',
              active: true,
            },
          ]
        }
      ]
    }
  },
  props:{
    obj: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  async created(){

  },
  computed:{
    integrations(){
      return this.$store.getters['integrations/get'];
    },
  },
  methods:{
    clickFunction(groupi, indexi){
      switch(groupi){
        case 0:
          switch(indexi){
            case 0:
              this.wordpressDialog();
              break;
            case 1:
              window.open(this.obj.url + '/wp-admin/authorize-application.php', '_blank');
              break;
            case 2:
              window.open(this.getWPAdmin(this.obj), '_blank');
              break;
            case 3:
              window.open(this.obj.url + `/`+this.obj.wpadmin+`/tools.php?page=health-check`, '_blank');
              break;
            case 4:
              window.open(this.obj.url+`/`+this.obj.wpadmin+`/admin.php?page=WordfenceScan`, '_blank');
              break;
            case 5:
              window.open(`https://orion.managewp.com/dashboard/site/`+this.obj.managewp+`/dashboard`, '_blank');
              break;
          }
          break;
        case 1:
          switch(indexi){
            case 0:
              window.open(`https://transparencyreport.google.com/safe-browsing/search?url=`+this.obj.url, '_blank');
              break;
            case 1:
              window.open(`https://www.siteadvisor.com/sitereport.html?url=`+this.obj.url, '_blank');
              break;
            case 2:
              window.open(`https://safeweb.norton.com/report/show?url=`+this.obj.url, '_blank');
              break;
            case 3:
              window.open(`https://sitecheck.sucuri.net/results/`+this.obj.url, '_blank');
              break;
            case 4:
              this.loadVirusTotal(this.obj.url);
              break;
          }
          break;
        case 2:
          switch(indexi){
            case 0:
              this.loadGTMetrix(this.obj);
              break;
            case 1:
              window.open(`https://pagespeed.web.dev/report?url=`+this.obj.url, '_blank');
              break;
            case 2:
              window.open(`https://wave.webaim.org/report#/`+this.obj.url, '_blank');
              break;
          }
          break;
      }
    },
    loadVirusTotal(url){
      this.$store.dispatch("scans/getVirusTotalURL", {url: url} )
        .then(response => {
          window.open(response, '_blank');
        });
    },
    loadGTMetrix(item){
      let self = this;
      item.gtm_loading = true;
      this.$store.dispatch("scans/getGTMetrixURL", {url: item.url} )
      .then(response => {
        if(response.error.error != undefined){
          self.systemMessage(response.error.error);
        }else{
          item.gtm_loading = false;
          window.open(response.results.report_url + '/pdf', '_blank');
        }
      })
    },
    wpExists(item){
      return (item.wp_exists == 0 || item.wp_exists == undefined) ? false : true;
    },
    isWP(item){
      return (item.framework != 'wordpress') ? false : true;
    },
    mwpExists(item){
      return (item.managewp == '' || item.managewp == null) ? false : true;
    },
    getWPAdmin(item){
      if(item.managewp){
        return 'https://orion.managewp.com/site-admin/' + item.managewp;
      }else if(item.wpadmin){
        return item.url + '/' + item.wpadmin;
      }else{
        return item.url + 'wp-admin';
      }
    },
    wordpressDialog () {
      this.dialogWordpress = true;
    },
  }
}
</script>

<style lang="scss" scoped>
  .v-sheet{
    display: flex; 
    align-items: center;
    justify-content: center;
  }
</style>