<template>
  <v-sheet class="mx-auto py-0" elevation="0" color="blue-grey darken-1" light centered min-height="84px">
    <v-card max-width="1160px" class="mx-auto height-85" elevation="0" color="transparent">
      <v-container class="height-85 pa-0">
        <v-row dense align="center" class="height-85 pa-0">
          <template v-if="title">
            <v-col cols="3" class="text-start pa-0">
              <div class="ma-0 text-body-1 blue-grey--text text--lighten-4 text-uppercase">{{title}}</div>
            </v-col>
            <v-col class="text-end pa-0">
              <slot></slot>
            </v-col>
          </template>
          <template v-if="!title">
            <slot></slot>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-sheet>
</template>

<script>
  export default {
    name: 'PageTitle.vue',
    data() {
      return {
      }
    },
    props:{
      title: {
        type: String,
        default: '',
      },
      icon: {
        type: [String, Boolean],
        default: false,
      },
    },
    async created(){

    },
  }
</script>

<style lang="scss" scoped>
  .height-85{
    height: 84px;
  }
</style>