import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import sweetAlert from "@/plugins/sweetalert";
import extractDomain from "extract-domain";
import { mapActions } from "vuex";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import Moment from "moment-timezone";

import router from "@/router";
import store from "@/stores";
import i18n from "@/lang";

import "@/services";
import "@/components";

export const mixin = {
  methods: mapActions({
    systemMessage: "system/setMessage",
  }),
};

if (process.env.VUE_APP_ENV != "LOCAL") {
  Sentry.init({
    Vue,
    dsn: "https://1ffe7abaa2f04a079afcdfdaa6f56127@o1432308.ingest.sentry.io/4504850531483648",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

Moment.tz.setDefault("America/Chicago");
Vue.mixin(mixin);
Vue.config.productionTip = false;

Vue.use(sweetAlert);

new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: (h) => h(App),
  data() {
    return {
      testing: false,
      domain: null,
    };
  },
  computed: {
    getDomain() {
      return this.domain;
    },
    getBaseDomain() {
      return this.getDomain != null ? extractDomain(this.getDomain) : null;
    },
    getEscapedDomain() {
      return escape(this.getDomain);
    },
  },
  methods: {
    clearAll() {
      this.domain = null;
    },
    tmpBaseDomain(str) {
      return extractDomain(str);
    },
  },
}).$mount("#app");
