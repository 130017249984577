<template>
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 119.99999">
    <path fill="#fbde2a" d="M120,60c0,33.07343-26.92656,60-60,60S0,93.07343,0,59.99999C0,26.87217,26.87217,0,60,0c9.13871,0,17.84225,2.06709,25.56664,5.7117l-9.95467,12.83772c-4.84134-1.8495-10.11786-2.82865-15.61197-2.82865-23.33636,0-42.53853,18.11423-44.17044,41.06981-.0544,.48957-.0544,.92475-.0544,1.41432,0,.59837-.0544,1.19673-.0544,1.8495,0,24.4243,19.85494,44.27924,44.27924,44.27924s44.27924-19.85494,44.27924-44.27924c0-3.26383-.38078-6.47326-1.03354-9.51949l11.58658-14.90481c3.31821,7.398,5.16771,15.66636,5.16771,24.3699"/>
    <path fill="#1e1f1d" d="M107.76065,23.71714l-45.58477,58.74886-28.93925-25.89302,10.49864-11.74977,16.37353,14.63282L96.60925,12.45693c4.18858,3.26383,7.94198,7.01723,11.1514,11.2602"/>
  </svg>
</template>

<script>
  export default {
    name: 'Norton.vue',
    props: {
      icon: {
        type: String,
        default: '$cancel'
      }
    }
  }
</script>