<template>
  <v-dialog v-model="value" width="80%" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">Add attachments to scan</span>
          <v-btn v-if="!loading" small dark fab absolute right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="pa-5">
            <v-overlay :value="loading" absolute color="indigo lighten-5">
              <v-progress-circular indeterminate size="64" color="primary" />
            </v-overlay>
            <v-row dense>
              <v-col>
                <v-fieldset-basic title="Attached Files (click to open)" class="filelist">
                  <v-chip v-for="item in fileList" :key="item.label" class="mr-1 text-capitalize" close @click:close="onFileRemove(item)">{{
                    item.label
                  }}</v-chip>
                </v-fieldset-basic>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-fieldset-basic title="Upload File">
                  <v-container>
                    <v-row dense class="mb-2">
                      <v-col>
                        <v-btn dark color="blue-grey darken-4" @click="onUploadFileAdd">
                          Add File
                          <v-icon size="medium" class="ml-2">mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col class="uploads">
                        <div v-for="(upload, index) in form.fields.uploads" :key="index" class="rows">
                          <v-text-field v-model="upload.label" label="Label" outlined class="labels" :rules="labelRules(index)" />
                          <v-file-input v-model="upload.file" label="Choose a file" accept="image/*,.pdf" :rules="fileRules" />
                          <v-btn v-if="!loading" small dark fab color="blue-grey darken-4" size="medium" @click="onUploadFileRemove(index)">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-fieldset-basic>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn v-if="!loading" x-large dark color="blue-grey darken-4" :disabled="!form.valid" @click="onSave">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "Attachment.vue",
  data() {
    return {
      form: {
        valid: false,
        fields: {
          id: 0,
          files: [],
          uploads: [{ label: "", file: null }],
        },
      },
      saving: false,
      loading: false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  mounted() {
    this.init();
  },
  created() {
    this.init();
  },
  computed: {
    fileRules() {
      return [(v) => !this.saving || !!v || "Please upload a file or remove the row"];
    },
    fileList() {
      return this.form.fields.files.filter((item) => item.removed === false);
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    init() {
      this.loading = false;
      this.saving = false;
      this.form.fields.id = this.item.id;
      this.form.fields.files = [];
      this.$set(this.form.fields, "uploads", [{ label: "", file: null }]);
    },
    labelRules(index) {
      return [(v) => !this.saving || !!v || "Label is required", (v) => this.isLabelUnique(v, index) || "Label must be unique"];
    },
    isLabelUnique(label, currentIndex) {
      return this.form.fields.uploads.filter((item, idx) => item.label === label && idx !== currentIndex).length === 0;
    },
    onFileRemove(file) {
      const index = this.form.fields.files.findIndex((item) => item === file);
      if (index !== -1) {
        this.form.fields.files[index].removed = true;
      }
    },
    onUploadFileAdd() {
      this.saving = false;
      this.form.fields.uploads.push({ file: null, label: "" });
    },
    onUploadFileRemove(index) {
      this.saving = false;
      this.form.fields.uploads.splice(index, 1);
    },
    onUploadFileChange(index, file) {
      this.saving = false;
      if (file) {
        this.form.fields.uploads[index].file = file;
      }
    },
    onSave() {
      this.saving = true;

      const isValid = this.$refs.form.validate();

      if (!isValid) {
        this.systemMessage("Please provide required fields!");
        return;
      }

      this.saving = false;
      this.loading = true;
      this.$emit("save", this.form.fields);
    },
  },
  watch: {
    value(to) {
      if (to) {
        this.init();
      }
    },
    "item.attachments"(to) {
      if (to) {
        this.form.fields.files = to.map((item) => ({ label: item.file_label, file: item.file_url, removed: false }));
      }
    },
  },
};
</script>

<style scoped>
.filelist >>> fieldset {
  min-height: 70px;
}
.uploads {
  max-height: 400px;
  overflow: auto;
}
.uploads .rows {
  display: flex;
  gap: 10px;
}
.uploads .labels {
  max-width: 230px !important;
  width: 230px !important;
}
</style>
