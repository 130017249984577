<template>
  <div class="no-results-message">
    {{message}}
  </div>
</template>

<script>
  export default {
    name: 'NoResults.vue',
    data() {
      return {
      }
    },
    props:{
      message: {
        type: String,
        default: 'No results found.',
      },
    },
    async created(){

    },
  }
</script>

<style scoped>
  .no-results-message{
    text-align: center;
    font-size: 12px;
    padding: 20px;
    background-color: rgba(158, 158, 158, 0.1);
    text-transform: uppercase;
    border-radius: 10px;
  }
</style>
