<template>
  <v-card elevation="0">
    <v-card-title>Account Info</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <th width="35%">Total Sites</th>
              <td colspan="2" v-if="sites">{{sites.length}}</td>
            </tr>
            <tr>
              <th>Total Scans</th>
              <td colspan="2" v-if="scans">{{scans.length}}</td>
            </tr>
            <tr v-if="user">
              <th>Account Expires</th>
              <td colspan="2">
                <span v-if="user.expires">
                  {{$date.fullDateTime(user.expires)}}
                </span>
                <span v-else>
                  Does not expire
                </span>
              </td>
            </tr>
            <tr v-if="credits">
              <th>Credits Remaining</th>
              <td>
                {{getRemainingCredits()}}
              </td>
              <td align="right">
                <v-btn to="/billing" color="blue-grey darken-4" small rounded dark>Buy More</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'AccountInfo.vue',
    data() {
      return {

      }
    },
    computed:{
      user(){
        return this.$store.getters['users/user'];
      },
      meta(){
        return this.$store.getters['userMeta/get'];
      },
      sites(){
        return this.$store.getters['sites/all'];
      },
      scans(){
        return this.$store.getters['scans/all'];
      },
      credits(){
        return this.$store.getters['transactions/credits'];
      },
    },
    methods:{
      getRemainingCredits(){
        if(this.credits){
          if(this.credits.user_frequency == '∞'){
            return 'Unlimited';
          }else{
            return this.credits.remaining_credits
          }
        }else{
          return 0;
        }
      }
    },
  }
</script>

<style>

</style>