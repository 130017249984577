<template>
  <v-card elevation="0">
    <v-card-title>Footer Disclaimer</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <p class="text-center">This can be found on the footer of all the pages of the generate report.</p>
          <v-form
          ref="form"
          v-model="form.valid"
          lazy-validation>
            <v-textarea
              v-model="form.fields.footer_disclaimer"
              width="100%"
              outlined
              label="Footer Disclaimer"
              hide-details
              :rules="[$utilities.rules.max500chars]"
            ></v-textarea>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row>
        <v-col class="text-center">
          <v-btn x-large dark color="blue-grey darken-4" @click="save">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'FooterDisclaimer.vue',
  data() {
    return {
      form:{
        valid: false,
        fields: {
          footer_disclaimer: '',
        },
      },
    }
  },
  computed:{
    meta(){
      return this.$store.getters['userMeta/get'];
    }
  },
  async created(){
    this.form.fields.footer_disclaimer = this.meta.footer_disclaimer;
  },
  methods:{
    save(){
      let self = this;
      if(this.$refs.form.validate()){
        this.$store.dispatch("userMeta/update", this.form.fields)
          .then(() => {
            self.systemMessage('Settings updated successfully!');
          });
      }
    },
  },
  watch:{
    meta (to){
      this.form.fields.footer_disclaimer = to.footer_disclaimer;
    }
  }
}
</script>

<style>

</style>