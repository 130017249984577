<template>
  <v-footer padless height="160px">
    <v-col class="text-center text-caption">
      <div class="logo-wrapper">
        <v-logo :show-png="true" :is-inline="true" width="100px"></v-logo>
      </div>
      &copy; Copyright 2022 Hounddog is a product of KeenWP.com. All Rights Reserved. 
    </v-col>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer.vue'
  }
</script>

<style lang="scss" scoped>
  .v-footer{
    background-color: transparent;
    .logo-wrapper{
      .logo{
        padding: 0;
      }
    }
  }
</style>