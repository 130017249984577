<template>
  <v-container v-if="credits && credits.remaining_credits == 0">
    <v-row dense>
      <v-col>
        <v-alert dense type="error" color="yellow darken-4" dark class="text-center mb-0">You currently have no credits remaining. <v-btn color="white" x-small light>Buy More</v-btn></v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'CreditsAlert.vue',
    computed: {
      credits(){
        return this.$store.getters['transactions/credits'];
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>