<template>
  <v-dialog v-model="value" max-width="700px" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">Wordpress Information</span>
          <v-spacer></v-spacer>
          <v-btn small dark fab right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="text-center">
                <p>If Wordfence is enabled, you will need to enable Application Passwords <a :href="wordfenceSettings">here</a></p>
                <p class="text-center">Get the WP Application Password <a :href="applicationPassword" target=_blank>here</a></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.username"
                  prepend-icon="mdi-lock"
                  type="text"
                  outlined
                  dense
                  placeholder="Account Username"
                  @keydown.enter.prevent
                  label="Account Username"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.fields.password"
                  prepend-icon="mdi-lock"
                  type="text"
                  outlined
                  dense
                  placeholder="Account Password"
                  @keydown.enter.prevent
                  label="Account Password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.wpadmin"
                  prepend-icon="mdi-folder"
                  type="text"
                  outlined
                  dense
                  required
                  placeholder="wp-admin"
                  @keydown.enter.prevent
                  label="WP Admin Directory"
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.fields.mwp_id"
                  prepend-icon="mdi-folder"
                  type="text"
                  outlined
                  dense
                  placeholder=""
                  @keydown.enter.prevent
                  label="ManageWP ID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-container>
            <v-row>
              <v-col class="text-start">
                <v-btn dark x-large color="red" @click="trash">Delete</v-btn>
              </v-col>
              <v-col class="text-end">
                <v-btn dark x-large color="blue-grey darken-4" @click="save">Save</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Wordpress.vue',
    data() {
      return {
        form: {
          valid: false,
          fields:{
            mwp_id: '',
            username: '',
            password: '',
            wpadmin: '',
          }
        },
      }
    },
    async created(){
      // if(this.value){
        // this.get();
      // }
    },
    emits: ['update:modelValue'],
    props:{
      value:{
        type: Boolean,
        default: false
      },
      id:{
        type: Number
      }
    },
    computed:{
      sites(){
        return this.$store.getters['sites/all'];
      },
      url(){
        let val = this.sites.filter(site => site.id == this.id)[0];
        if(val){
          return val.url;
        }
        return null;
      },
      wordfenceSettings(){
        return this.url + '/wp-admin/admin.php?page=WordfenceOptions#wf-option-loginSec-disableApplicationPasswords';
      },
      applicationPassword(){
        return this.url + '/wp-admin/authorize-application.php';
      },
    },
    methods:{
      updateValue (value) {
        this.$emit('input', value);
      },
      async get(){
        let self = this;
        await this.$store.dispatch("wordpress/get", {
          'client_id': self.id
        }).then((response) => {
          self.form.fields.username = response.username;
          self.form.fields.password = response.password;
          self.form.fields.wpadmin = response.wpadmin;
        });
        await this.$store.dispatch("managewp/get", {
          'client_id': self.id
        }).then((response) => {
          let mwp_id = response.data.mwp_id;
          self.form.fields.mwp_id = mwp_id;
        });
      },
      async save () {
        let self = this;
        if(this.$refs.form.validate()){
          await this.$store.dispatch("wordpress/upsert", {
            username: this.form.fields.username,
            password: this.form.fields.password,
            wpadmin: this.form.fields.wpadmin,
            client_id: this.id
          })
          await this.$store.dispatch("managewp/upsert", {
            mwp_id: this.form.fields.mwp_id,
            client_id: this.id
          })
          .then(() => {
            self.updateValue(false);
          });
        }else{
          this.systemMessage('Please validate the form!');
        }
      },
      async trash () {
        let self = this;
        await this.$store.dispatch("wordpress/delete", { 
          client_id: this.id 
        })
        .then(() => {
          self.updateValue(false);
          self.form.fields = self.defaultItems;
        });
      },
    },
    watch:{
      value(){
        this.get();
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>