<template>
  <v-dialog v-model="value" max-width="800px" persistent>
    <v-form
      ref="form"
      v-model="form.valid"
      lazy-validation>
      <v-card>
        <v-card-text>
          <v-btn small dark fab absolute right text color="black" @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-container fluid>
            <v-row dense>
              <v-col>
                <img :src="require('@/assets/integration-logos/gtmetrix.svg')" width="150px" class="integration-logos" />
                <h4 class="text-center py-5">For more information about how to get this setup, please visit the official GTMetrix <a href="https://gtmetrix.com/api/docs/2.0/" target="_blank">documentation</a>.</h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="form.fields.url"
                  prepend-icon="mdi-lock"
                  :rules="[$utilities.rules.required]"
                  label="API URL"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="form.fields.email"
                  prepend-icon="mdi-lock"
                  :rules="[$utilities.rules.required]"
                  label="Email"
                  required
                  outlined
                  type="email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="form.fields.key"
                  prepend-icon="mdi-lock"
                  :rules="[$utilities.rules.required]"
                  label="Key"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container> 
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-center">
              <v-btn x-large dark color="blue-grey darken-4" @click="save">
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>  
    </v-form>
  </v-dialog>
</template>

<script>
  export default {
    name: 'GTMetrix.vue',
    data() {
      return {
        form: {
          valid: false,
          fields:{
            url: '',
            email: '',
            key: '',
          }
        },
      }
    },
    async created(){
      this.get()
    },
    emits: ['update:modelValue'],
    props:{
      value:{
        type: Boolean,
        default: false
      },
    },
    methods:{
      updateValue (value) {
        this.$emit('input', value);
      },
      async get(){
        let self = this;
        await this.$store.dispatch("gtmetrix/get")
          .then((data) => {
            self.form.fields = { ...data };
          });
      },
      async save () {
        if(this.$refs.form.validate()){
          this.$store.dispatch("gtmetrix/upsert", this.form.fields)
            .then(() => {
              this.systemMessage('Settings saved!');
              this.updateValue(false);
            });
        }
      },
    },
    watch:{
      value(){
        this.get();
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>