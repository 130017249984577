import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/transactions';
const endpoints = {
  all:              model+'/all',
  activate:         model+'/activate',
  update:           model+'/update',
  insert:           model+'/insert',
  delete:           model+'/delete',
  credits:          model+'/credits',
  credit:           model+'/credit',
}
const mutation_calls = {
  all:              'SET_ALL',
  insert:           'SET_INSERT',
  update:           'SET_UPDATE',
  activate:         'SET_ACTIVATE', 
  delete:           'SET_DELETE',
  credits:          'SET_CREDITS',
  credit:           'SET_CREDIT',
}

const state = () => ({
  all: null,
  credits: null,
})

// getters
const getters = {
  all: state => state.all,
  credits:  state => state.credits,
}

// actions
const actions = {
  all(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      if(context.rootGetters['transactions/all'] != null){
        return;
      }
      let apiParams = { 
        'user_id': user.id
      };
      apiService.post(endpoints.all, apiParams)
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("users.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  insert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.insert, {
        'user_id': user.id,
        'quantity': fields.quantity,
        'source': fields.source,
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.insert, payload);
        context.dispatch('system/setMessage', i18n.t("transactions.insert.status.success"), { root: true })
        document.location.href = payload.destination;
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("transactions.insert.status.error"), { root: true })
        reject(error);
      });
    });
  },
  update(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.update, {
        'user_id': user.id,
        'token': fields.token,
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.update, fields);
        context.dispatch('system/setMessage', i18n.t("transactions.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("transactions.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  activate(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.activate, {
        'user_id': user.id,
        'token': fields.token,
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.activate, fields);
        context.dispatch('system/setMessage', i18n.t("transactions.activate.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("transactions.activate.status.error"), { root: true })
        reject(error);
      });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.delete, {
        'user_id': user.id,
        'token': fields.token,
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.delete, fields);
        context.dispatch('system/setMessage', i18n.t("transactions.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("transactions.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async credits(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.credits, {
        'user_id': user.id,
      })
      .then(response => {
        let payload = response.data[0];
        context.commit(mutation_calls.credits, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async credit(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.credit, {
        'user_id': fields.user_id,
        'quantity': fields.quantity,
        'source': fields.source,
        'sendEmail': fields.sendEmail,
      })
      .then((response) => {
        let payload = response;
        context.commit(mutation_calls.credit, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },

}

// mutations
const mutations = {
  SET_ALL(state, payload) {
    state.all = payload
  },
  SET_INSERT() {
  },
  SET_UPDATE() {
  },
  SET_ACTIVATE() {
  },
  SET_DELETE() {
  },
  SET_CREDITS(state, payload) {
    state.credits = payload;
  },
  SET_CREDIT(state, payload) {
    console.log(state, payload);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}