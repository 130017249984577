<template>
  <div :class="{'company-logo' : true, 'inline' : isInline}">
    <img :src="getImage" :style="styles" />
  </div>
</template>

<script>
  export default {
    name: 'CompanyLogo.vue',
    data() {
      return {}
    },
    props:{
      isInline:{
        type: Boolean,
        default: false
      },
      override:{
        type: String,
        default: null
      },
      width:{
        type: String
      },
      height:{
        type: String
      },
    },
    computed:{
      getImage(){
        if(this.override != null){
          return this.override;
        }
        return this.userMeta.branding;
      },
      userMeta(){
        return this.$store.getters['userMeta/get'];
      },
      styles(){
        if(this.width){
          return {'max-width': this.width, 'max-height': this.height}
        }
        return {};
      },
    }
  }
</script>

<style lang="scss">
  .company-logo{
    max-width: 160px;
    max-height: 70px;
    margin: 20px auto;
    &.big{
      width: 300px;
      max-width: none;
      margin: 20px auto;
    }
    &.icon{
      width: 50px;
      height: 50px;
    }
    &.inline{
      display: inline-block;
    }
    img{
      max-width: 100%;
      max-height: 70px;
      margin: auto;
      display: block;;
    }
    &.onlyPrint{
      display: none;
    }
  }
  @media print {
    .logo{
      .onlyPrint{
        display: flex !important;
      }
    }
  }
</style>