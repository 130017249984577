import { render, staticRenderFns } from "./CompanyCalendar.vue?vue&type=template&id=5df5a140&scoped=true&"
import script from "./CompanyCalendar.vue?vue&type=script&lang=js&"
export * from "./CompanyCalendar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df5a140",
  null
  
)

export default component.exports