import Vue              from 'vue'
import Vuex             from 'vuex'

import gtmetrix         from './gtmetrix'
import integrations     from './integrations'
import knowledgebase    from './knowledgebase'
import logs             from './userLogs'
import managewp         from './managewp'
import messages         from './messages'
import userMeta         from './userMeta'
import scans            from './scans'
import sites            from './sites'
import siteNotes        from './siteNotes'
import slack            from './slack'
import support          from './support'
import system           from './system'
import transactions     from './transactions'
import users            from './users'
import uptimeRobot      from './uptimeRobot'
import wordpress        from './wordpress'

Vue.use(Vuex)

const debug = process.env.ENV !== 'PROD'

export default new Vuex.Store({
  modules: {
    gtmetrix,
    integrations,
    knowledgebase,
    logs,
    managewp,
    messages,
		scans,
    sites,
    siteNotes,
		slack,
    support,
    system,
    transactions,
    uptimeRobot,
    users,
    userMeta,
		wordpress,
  },
  strict: debug,
})
