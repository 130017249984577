<template>
  <v-dialog v-model="value" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Delete Site</span>
        <v-spacer></v-spacer>
        <v-btn small dark fab right text @click="updateValue(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <b>Are you sure you want to delete <b>{{getSiteName}}</b>?</b>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn x-large dark color="blue-grey darken-4" @click="save">Yes</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SitesDelete.vue',
    data() {
      return {
      }
    },
    emits: ['update:modelValue'],
    props:{
      value:{
        type: Boolean,
        default: false
      },
      id:{
        type: [Number, String]
      },
    },
    computed:{
      sites(){
        return this.$store.getters['sites/all'];
      },
      getSiteName(){
        let site = this.sites.find(site => site.id == this.id);
        if(site){
          return site.name;
        }
        return "Loading...";
      }
    },
    methods:{
      updateValue (value) {
        this.$emit('input', value);
      },
      async save(){
        let self = this;
        let index = this.sites.findIndex(site => site.id == this.id);
        await this.$store.dispatch("sites/delete", {
          id: this.id,
          index: index
        }).then(() => {
          self.updateValue(false);
          self.$router.push({ name: 'Sites' });
        });
      }
    }
  }
</script>

<style>

</style>