<template>
  <v-dialog v-model="value" max-width="800px" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card min-height="350px">
        <v-card-title>
          <span class="text-h5">Add a New Site</span>
          <v-spacer></v-spacer>
          <v-btn small dark fab right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-5">
            <v-row align="center">
              <v-col>
                <v-text-field
                  label="Site Name *"
                  v-model="form.fields.name"
                  type="text"
                  outlined
                  dense
                  required
                  placeholder="Site Name"
                  @keydown.enter.prevent
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Site URL *"
                  v-model="form.fields.url"
                  outlined
                  dense
                  placeholder="domain.com"
                  :rules="[$utilities.rules.required, $utilities.rules.isUrl]"
                  @keydown.enter.prevent
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <v-select
                  label="Framework *"
                  v-model="form.fields.framework"
                  :items="$strings.frameworkOptions()"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  required
                  placeholder="Framework"
                  @keydown.enter.prevent
                  :rules="[$utilities.rules.required]"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.fields.host"
                  outlined
                  dense
                  required
                  label="Host Name"
                  placeholder="Host Name"
                  @keydown.enter.prevent
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <v-text-field
                  label="Started On"
                  v-model="form.fields.started_on"
                  type="date"
                  outlined
                  dense
                  required
                  placeholder="Started"
                  @keydown.enter.prevent
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  label="Frequency"
                  v-model="form.fields.frequency"
                  :items="$strings.frequencyOptions()"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  required
                  placeholder="Frequency"
                  @keydown.enter.prevent
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn x-large dark color="blue-grey darken-4" @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'SitesInsert.vue',
  data() {
    return {
      form: {
        valid: false,
        defaults: {
          name: '',
          url: '',
          framework: "wordpress",
          host: '',
          started_on: '',
          frequency: 'monthly',
        },
        fields: {
          name: '',
          url: '',
          framework: "wordpress",
          host: '',
          started_on: '2023-01-01 00:00:00',
          frequency: 'Monthly',
        }
      },
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: [Number, String]
    },
  },
  created(){
    this.form.fields.started_on = new Date().toISOString().substr(0, 10);
  },
  computed:{
    site(){
      return this.$store.getters['sites/get'];
    },
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async save(){
      if(this.$refs.form.validate()){
        await this.$store.dispatch("sites/insert", {
          ...this.form.fields
        });
        this.form.fields = this.form.defaults;
        this.updateValue(false);
      }else{
        this.systemMessage('Please validate the form!');
      }
    }
  }
}
</script>

<style>

</style>