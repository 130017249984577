import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/support';
const endpoints = {
  all:              model+'/all',
  insert:           model+'/insert',
}
const mutation_calls = {
  all:              'SET_ALL',
  insert:           'SET_INSERT',
}

const state = () => ({
  all: [],
})

// getters
const getters = {
  all: state => state.all,
}

// actions
const actions = {
  all(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      if(context.rootGetters['support/all'] != null){
        return;
      }
      apiService.post(endpoints.all, {
        'user_id': user.id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true });
        reject(error);
      });
    });
  },
  insert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.insert, {
        'message': fields.message,
        'system_info': JSON.stringify(window.navigator),
        'subject': fields.subject,
        'user_id': user.id,
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.insert, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
}

// mutations
const mutations = {
  SET_ALL(state, payload) {
    state.all = payload.sort((t1,t2) => t1.id < t2.id ? -1 : 1);
  },  
  SET_INSERT(state, payload) {
    state.all.push(payload);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}