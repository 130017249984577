<template>
  <v-card v-if="show && results" class="pa-5">
    <pre class="spellcheck-results" v-html="results"></pre>
  </v-card>
</template>

<script>
  export default {
    name: 'Spelling.vue',
    props:{
      show: {
        type: Boolean,
        default: false,
      },
      results: {
        type: Object,
        default() {
          return {}
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>