import Vue            from 'vue'
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/site-notes';
const endpoints = {
  all:              model+'/all',
  get:              model+'/get',
  upsert:           model+'/upsert',
  delete:           model+'/delete'
}
const mutation_calls = {
  all:              'SET_ALL',
  get:              'SET_GET',
  insert:           'SET_INSERT',
  update:           'SET_UPDATE',
  upsert:           'SET_UPSERT',
  delete:           'SET_DELETE'
}

const state = () => ({
  all: null,
  get: null,
})

const getters = {
  all: state => state.all,
  get: state => state.get,
}

const actions = {
  async all(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.all,{
        'client_id': fields.client_id,
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      }, error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async get(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.get, {
        'id': fields.id
      })
      .then(response => {
        let payload = response.data;
        delete response.data.id;
        context.commit(mutation_calls.get, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("general.error"), { root: true })
        reject(error);
      });
    });
  },
  async upsert(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.upsert, {
        'note': fields.note,
        'client_id': fields.client_id,
        'source': fields.source,
        'id': fields.id
      })
        .then(response => {
          let payload = response;
          if(fields.id){
            context.commit(mutation_calls.update, fields);
          }else{
            context.commit(mutation_calls.insert, fields);
          }
          context.dispatch('system/setMessage', i18n.t("notes.update.status.success"), { root: true })
          resolve(payload);
        })
        .catch(error => {
          context.dispatch('system/setMessage', i18n.t("notes.update.status.error"), { root: true })
          reject(error);
        });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.delete, {
        'id': fields.id
      })
      .then( response => {
        let payload = response;
        context.commit(mutation_calls.delete, fields.index);
        context.dispatch('system/setMessage', i18n.t("notes.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("notes.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

const mutations = {
  SET_ALL(state, payload) {
    state.all = payload;
  },  
  SET_GET(state, payload) {
    state.get = payload;
  },  
  SET_INSERT(state, payload) {
    state.all.push(payload);
  },
  SET_UPDATE(state, payload) {
    let index = state.all.findIndex(note => note.id == payload.id);
    Vue.set(state.all, index, {...payload})
  },
  SET_UPSERT(state, payload) {
    state.get = payload;
  },
  SET_DELETE(state, payload) {
    state.all.splice(payload, 1);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}