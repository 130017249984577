<template>
  <v-dialog v-model="value" max-width="650px" persistent>
    <v-form ref="form" v-model="form.valid">
      <v-card>
        <v-card-title>
          <span class="text-h5">Gift Credits</span>
          <v-btn small dark fab absolute right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense align="center">
              <v-col cols="6">
                <v-text-field
                  v-model="form.fields.quantity"
                  type="number"
                  outlined
                  required
                  min="1"
                  max="100"
                  placeholder="Credits"
                  @keydown.enter.prevent
                  label="Credits"
                  hide-details
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="form.fields.recurring"
                  label="Make this recurring?"
                  inset
                  :true-value="1"
                  :false-value="0"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col>
                <v-switch
                  v-model="form.fields.sendEmail"
                  label="Email the user about this gift?"
                  inset
                  :true-value="1"
                  :false-value="0"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn x-large dark color="blue-grey darken-4" @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'UsersGift.vue',
  data() {
    return {
      form:{
        valid: false,
        fields:{
          quantity: '1',
          sendEmail: false,
          recurring: false,
        }
      }
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: Number
    },
  },
  computed:{
    users(){
      return this.$store.getters['users/all'];
    },
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async save(){
      let self = this;
      if(this.$refs.form.validate()){
        await this.$store.dispatch('transactions/credit', {
          user_id: this.id,
          quantity: this.form.fields.quantity, 
          sendEmail: this.form.fields.sendEmail,
          source: 'free',
        }).then(() => {
          self.updateValue(false);
          self.systemMessage('User credited!');
        });
      }else{
        this.systemMessage('Please validate the form!');
      }
    }
  }
}
</script>

<style>

</style>